<template>
  <div>
    <Dialog
      :modal="true"
      :closable="false"
      :closeOnEscape="false"
      :visible="isVisible"
      :style="{width: '700px'}"
      :contentStyle="{flex: '1'}"
      :baseZIndex="10000"
      pt:header:class="fusion_modal_header"
      pt:content:class="fusion_modal_content"
    >
      <template #header>
        <h3 class="text-xl font-bold">
          <span>{{ $t('account_reorg.fusion_workflow.modal_title') }}</span>
        </h3>
      </template>

      <template #default>
        <Svgall
          class="fusion_modal_image"
          :name="'hour_glass'"
          :color="primaryColor"
        />
        <div>
          {{ $t('account_reorg.fusion_workflow.modal_content_1') }}
          <br><br>
          {{ $t('account_reorg.fusion_workflow.modal_content_2') }}
        </div>
      </template>

      <template v-slot:footer>
        <div class="flex ">
          <button
            class="fusion_modal_button"
            :style="[primaryColor ? { background: primaryColor } : '']"
            @click="doLogout"
          >
            {{ $t('header.signout') }}
          </button>
        </div>
      </template>

    </Dialog>
  </div>
</template>

<script>
import Dialog from 'primevue/dialog';
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'AccountFusionModal',
  components: {
    Dialog,
  },
  //mixins: [appMixin],
  data() {
    return {
      isVisible: true,
      primaryColor: '',
    }
  },
  created() {
    this.primaryColor = this.getCurrentUser.primaryColor ? this.getCurrentUser.primaryColor : '#43ac6f';
  },
  computed: {
    ...mapGetters(['getCurrentUser']),
  },
  methods: {
    ...mapActions(['logout']),
    doLogout() {
      localStorage.removeItem('client_logged_in')
      this.logout()
    },
  },
};
</script>

<style lang="css" >
.fusion_modal_header {
  justify-content: center;
}
.fusion_modal_content {
  text-align: center;
}
.fusion_modal_image {
  display: block;
  width: 88px;
  height: 88px;
  margin-left: auto;
  margin-right: auto;
}
a.fusion_modal_button,
button.fusion_modal_button {
  background: #43ac6f;
  border: 0;
  border-radius: 0;
  color: #fff;
  display: block;
  height: 40px;
  line-height: 40px;
  text-align: center;
  text-decoration: none;
  width: 200px;
}
</style>